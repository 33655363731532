<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4"> 
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Payroll </h3> 
          <div class="d-flex justify-content-end mb-3"> 
            <button type="button" class="button-1 col-md-2  btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Add Integration
            </button> 
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="text-start modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Select Payment Integration</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form action="" class="mb-3">
                      Paypal: Activate this option for payroll
                      <button type="button" class="button-1 btn btn-primary">Config</button>
                    </form>
                    <form action="" class="mb-3">
                      LocalBitcoin <button type="button" class="button-1 btn btn-primary">Config</button>
                    </form>
                  </div>
                  <div class="modal-footer">
                  </div>
                </div>
              </div>
            </div> 
          </div> 
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <div class="d-flex justify-content-center">
                  <h5> Don't have payment method config</h5>
                </div>                         
              </div>
            </div>

                               
          </div>
        </div>                
      </div>
    </div> 
  </main>
</template>

<script>
  export default
  {
    name: 'Payroll',
   
    
  }
</script>

<style scoped></style>
