<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentPayroll /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentPayroll from '@/components/team/dashboard/modules/payroll/ContentPayroll.vue'

export default 
{
  name: 'Plan',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentPayroll
  }
}
</script>
<style>

</style>